import { handleFirstInstall } from './first-install/handleFirstInstall';
import { setPortfolioPagesStates } from '../utils/pagesUtils';
import { editorAppEventListener } from './editorAppEventListener';
import {
  EditorScriptFlowAPI,
  FlowEditorSDK,
  FlowPlatformOptions,
} from '@wix/yoshi-flow-editor';
import { AppDefId } from '../constants';

export const onEditorReady = async (
  editorSDK: FlowEditorSDK,
  appDefId: string,
  options: FlowPlatformOptions,
  flowAPI: EditorScriptFlowAPI,
): Promise<void> => {
  if (options.firstInstall) {
    const areAppPagesInstalled = await editorSDK.pages.getApplicationPages('');
    if (areAppPagesInstalled.length >= 3) {
      await editorSDK.editor.deeplink.show('', {
        type: 'pagesPanel',
        params: [AppDefId],
      });
    } else {
      flowAPI.fedops.interactionStarted('portfolio-first-install');
      try {
        await handleFirstInstall(editorSDK, appDefId, options, flowAPI);
      } catch (e: any) {
        flowAPI.reportError(e);
        console.error('handleFirstInstall failed', { e });
        await editorSDK.application.uninstall('', { openConfirmation: false });
      }
      flowAPI.fedops.interactionEnded('portfolio-first-install');
    }
  }
  setPortfolioPagesStates(editorSDK);
  editorAppEventListener(editorSDK, flowAPI, options.origin.type);
};
