import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { WidgetBuilder, FlowEditorSDK, FlowAPI } from '@wix/yoshi-flow-editor';
import {
  customizeNativeGalleryGFPPs,
  getRole,
} from '../../utils/manifestUtils';
import { openDashboardCollections } from '../../utils/dashboardUtils';
import { CollectionsGalleryWidgetComponentIds } from './config/constants';

const setConfigureConnectedComponents = ({
  controllerBuilder,
  editorSDK,
  flowAPI,
}: {
  controllerBuilder: WidgetBuilder;
  editorSDK: FlowEditorSDK;
  flowAPI: FlowAPI;
}) => {
  const { t } = flowAPI.translations;
  const removeCollectionsPage = flowAPI.experiments.enabled(
    'specs.portfolio.removeCollectionsPage',
  );
  controllerBuilder.configureConnectedComponents(
    getRole(CollectionsGalleryWidgetComponentIds.GalleyWidget),
    (componentBuilder) => {
      componentBuilder.gfpp().set('mainAction2', {
        label: t('Collection_Widget_Connected_Component_Collections_Gallery'),
        onClick: openDashboardCollections(editorSDK, removeCollectionsPage),
      });
    },
  );
};

export const getWidgetManifest: GetWidgetManifestFn = (
  controllerBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  flowAPI: FlowAPI,
) => {
  const { t } = flowAPI.translations;
  controllerBuilder.set({
    displayName: t('Widget_Name_Collections_Gallery_Widget'),
    nickname: 'collectionsGalleryWidget',
  });
  customizeNativeGalleryGFPPs({
    controllerBuilder,
    galleryRole: 'collectionsGallery',
    shouldHideSettingsGfpp: true,
    t,
  });
  setConfigureConnectedComponents({ controllerBuilder, editorSDK, flowAPI });
};
